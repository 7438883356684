import { render, staticRenderFns } from "./AboutKECMain.vue?vue&type=template&id=618868a4&scoped=true&"
import script from "./AboutKECMain.vue?vue&type=script&lang=js&"
export * from "./AboutKECMain.vue?vue&type=script&lang=js&"
import style0 from "./AboutKECMain.vue?vue&type=style&index=0&id=618868a4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "618868a4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/ctweb/fontend/kecc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('618868a4')) {
      api.createRecord('618868a4', component.options)
    } else {
      api.reload('618868a4', component.options)
    }
    module.hot.accept("./AboutKECMain.vue?vue&type=template&id=618868a4&scoped=true&", function () {
      api.rerender('618868a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DefaultHome/KECC/AboutUs/AboutKEC/AboutKECMain.vue"
export default component.exports