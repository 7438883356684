<template>
    <div id="kec" class="solutions-area section-width ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>{{ $t('navbar.menu_4') }}</h2>
            </div>
            <div class="row">

                <div class="col-lg-6">
                    <div class="approach-img">
                        <img src="~assets/project_images/pic/aboutkec.jpg" alt="Image">
                    </div>
                </div>

                <div class="col-lg-6 pt-2">
                    <div class="approach-content">
                        <h3>{{ $t('section_1.item_1.title') }}</h3>
                        <p v-html="$t('section_1.item_1.content')">
                        </p>
                        <ul>
                            <li>
                                <i class="far fa-star"></i>
                                <h4>{{ $t('section_1.item_2.title') }}</h4>
                                <p v-html="$t('section_1.item_2.content')">
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="terms-conditions-area">
            <div class="container">
                <div class="single-privacy">
                    <h4 class="mt-5 mb-4">{{ $t('section_2.title') }}</h4>
                    <div class="row">
                        <div class="col-lg-6">
                            <ul v-html="$t('section_2.item_1.content')">
                                <!-- <li><i class="fas fa-award"></i>2019年度-高雄市政府環境保護局室內空氣品質優良場所評鑑 優等獎</li>
                                <li><i class="fas fa-award"></i>2018年度-AFECA 亞洲會展獎最佳場館 第三名</li>
                                <li><i class="fas fa-award"></i>2016年度-ISO 20121 認證</li>
                                <li><i class="fas fa-award"></i>2015年度-國家建築金獎</li>
                                <li><i class="fas fa-award"></i>2015年度-第13屆民間參與公共建設金擘獎--佳等獎</li>
                                <li><i class="fas fa-award"></i>2015年度-ISO/CNS 14064-1認證</li>
                                <li><i class="fas fa-award"></i>2014年度-內政部友善展演所</li>
                                <li><i class="fas fa-award"></i>2014年度-高雄市無障礙友善環境評選活動 友善認證標章</li> -->
                            </ul>
                        </div>
                        <div class="col-lg-6">
                            <ul v-html="$t('section_2.item_2.content')">
                                <!-- <li><i class="fas fa-award"></i>2014年度-高雄市友善建築/高雄市政府獎狀</li>
                                <li><i class="fas fa-award"></i>2014年度-台灣優良智慧綠建築代表</li>
                                <li><i class="fas fa-award"></i>2014年度-工程優良獎</li>
                                <li><i class="fas fa-award"></i>2014年度-國家卓越建設獎(最佳施工品質類-卓越獎)</li>
                                <li><i class="fas fa-award"></i>2013年度-第21屆建築園冶獎(公共建築景觀類)</li>
                                <li><i class="fas fa-award"></i>2013年度-國際宜居城市獎(建築專案類金獎)</li>
                                <li><i class="fas fa-award"></i>2012年度-國家卓越建設獎(最佳規劃設計獎-卓越獎)</li>
                                <li><i class="fas fa-award"></i>2011年度-第19屆中華建築金石獎(優良公共建設類 -規劃設計組-首獎)</li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="grph-area ptb-100 mt-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="grph-img">
                            <img src="~assets/project_images/pic/aboutkec2.jpg" alt="Image">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="graph-content">
                            <h3>{{ $t('section_3.title') }}</h3>
                            <p>
                                {{ $t('section_3.item_1.content') }}
                            </p>
                            <p>
                                {{ $t('section_3.item_2.content') }}
                                <ul class="pl-4" v-html="$t('section_3.item_3.content')">
                                    <!-- <li>1. 客戶至上-建立長期且緊密的客戶關係。提供切合客戶所需的優質、專業且縝密的服務。</li>
                                    <li>2. 尊重負責-對資源的使用負責(環保/節能/高效)，重視待人處事的態度，尊重所有展館使用人員之健康與安全。</li>
                                    <li>3. 多元創新-不斷自我挑戰，持續學習與進步。善用無限創意，追求極致卓越。</li>
                                    <li>4. 積極主動-駕馭先機、主動決策、聽取建議、審時度勢，達成有效經營管理。</li>
                                    <li>5. 創造價值-依據我們的使命與目標，為所有的客戶與夥伴創造最高的價值。</li> -->
                                </ul>
                                {{ $t('section_3.item_4.content') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <div class="terms-conditions-area ptb-100">
            <div class="container">
                <div class="single-privacy">
                    <h3>{{ $t('section_4.title') }}</h3>
                    <span class="font-weight-bold">{{ $t('section_4.item_1.content') }}</span>
                    <p>
                        {{ $t('section_4.item_2.content') }}
                    </p>
                    <span class="font-weight-bold">{{ $t('section_4.item_3.content') }}</span>
                    <p>
                        {{ $t('section_4.item_4.content') }}
                    </p>
                    <div class="promise text-left mt-4 mb-5 d-flex justify-content-center" v-html="$t('section_4.item_5.content')">
                        遵守活動永續相關法令及相關要求；<br>
                        建立目標持續改善，提升永續績效；<br>
                        打造安全、健康且環保的工作環境；<br>
                        減少天然資源浪費，致力節能減碳；<br>
                        落實展館永續作法，溝通傳承經驗；<br>
                        結合社區社群政府資源，永續發展；<br>
                        支持當地產品服務，確保永續採購；<br>
                        提供多樣創新服務，永續營運獲利。<br>
                        <span class="text-right"> 高雄展覽館股份有限公司 總經理 涂宇欣 </span>
                    </div>
                    <figure class="figure mt-3">
                        <img src="~assets/project_images/pic/sgsAward.jpg" class="img-fluid rounded mx-auto d-block"
                            alt="Image">
                        <figcaption class="figure-caption text-left">{{ $t('section_4.item_6.content') }}</figcaption>
                    </figure>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
    export default {
        name: 'JoinUsContent',
        data() {
            return {}
        },
    }
</script>
<style>
    #kec li i {
        margin-right: 10px;
        font-size: 24px;
    }
    #kec p, #kec li, #kec .promise{
        font-size: 16px;
    }
    #kec .promise span{
        display: contents;
    }
</style>
<style scoped lang="scss">
    .promise {
        position: relative;
    }
    .single-privacy span{
        font-size: 18px;
    }
    .signature{
        margin-left: 100px;
    }
    .promise span {
        position: relative;
        top: 30px;
        left: 80px;
    }

    .graph-content h3,p {
        margin-bottom: 15px;
        color: #000;
    }
    .approach-img,
    .grph-img {
        height: 100%;
    }

    .approach-img img,
    .grph-img img {
        height: 100%;
        object-fit: cover;
    }

    .img-fluid {
        width: 100%;
    }

    .grph-area {
        background-color: #f5f5f5;
    }

    /* .grph-area > .container >.row{
        color: #000;
    } */
    .approach-content h3,
    .approach-content h4 {
        margin-bottom: 15px;
    }

    .terms-conditions-area{
        background-color: #000033;
        .container .single-privacy {
            h4{
                color: #FFF ;
                padding-top:50px;
            }
            ul{
                color: #FFF;
            }

            .row{
                padding-bottom: 30px;
            }
            
        }    
    }


    @media (max-width: 576px) {
        .approach-img img,
        .grph-img img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }

    }
</style>