var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "solutions-area section-width ptb-100",
      attrs: { id: "kec" }
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "section-title" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$t("navbar.menu_4")))])
        ]),
        _c("div", { staticClass: "row" }, [
          _vm._m(0),
          _c("div", { staticClass: "col-lg-6 pt-2" }, [
            _c("div", { staticClass: "approach-content" }, [
              _c("h3", [_vm._v(_vm._s(_vm.$t("section_1.item_1.title")))]),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(_vm.$t("section_1.item_1.content"))
                }
              }),
              _c("ul", [
                _c("li", [
                  _c("i", { staticClass: "far fa-star" }),
                  _c("h4", [_vm._v(_vm._s(_vm.$t("section_1.item_2.title")))]),
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_1.item_2.content"))
                    }
                  })
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "terms-conditions-area" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "single-privacy" }, [
            _c("h4", { staticClass: "mt-5 mb-4" }, [
              _vm._v(_vm._s(_vm.$t("section_2.title")))
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-6" }, [
                _c("ul", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("section_2.item_1.content"))
                  }
                })
              ]),
              _c("div", { staticClass: "col-lg-6" }, [
                _c("ul", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("section_2.item_2.content"))
                  }
                })
              ])
            ])
          ])
        ])
      ]),
      _c("section", { staticClass: "grph-area ptb-100 mt-5" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(1),
            _c("div", { staticClass: "col-lg-6" }, [
              _c("div", { staticClass: "graph-content" }, [
                _c("h3", [_vm._v(_vm._s(_vm.$t("section_3.title")))]),
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.$t("section_3.item_1.content")) + " ")
                ]),
                _c("p", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("section_3.item_2.content")) + " "
                  ),
                  _c("ul", {
                    staticClass: "pl-4",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_3.item_3.content"))
                    }
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("section_3.item_4.content")) + " ")
                ])
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-6" }, [
      _c("div", { staticClass: "approach-img" }, [
        _c("img", {
          attrs: {
            src: require("assets/project_images/pic/aboutkec.jpg"),
            alt: "Image"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-6" }, [
      _c("div", { staticClass: "grph-img" }, [
        _c("img", {
          attrs: {
            src: require("assets/project_images/pic/aboutkec2.jpg"),
            alt: "Image"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }